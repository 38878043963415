





































































import Component, { mixins } from 'vue-class-component'
import { State } from '@/mixins/state'
import Content from '@/components/Content.vue'
import Toolbar from '@/components/Layout/Toolbar.vue'
import FormButton from '@/components/Form/Button.vue'
import { Cancellation, User } from '@/types'
import { formatDate } from '@/util/formatDate'

import {
  SubscriptionApi,
  CancelSubscriptionCommand,
  CancelReasonId
} from '@/api'

@Component({
  components: {
    Toolbar,
    Content,
    FormButton
  },
  methods: {
    formatDate
  }
})
export default class ConfirmCancellation extends mixins(State) {
  processDate: Date | undefined
  isCancelledAndReadyToEnroll: boolean

  get currentUser (): User {
    return this.$store.state.currentUser
  }

  get cancellationInfo (): Cancellation {
    return this.$store.state.cancel.cancellation
  }

  get isChangingSportLocation (): boolean {
    return this.cancellationInfo.cancelReasonId === CancelReasonId.ChangeSportLocation
  }

  public navigateToRegistrationFlow () {
    // Link the user to the registrationFlow so they can select thier new sportlocation.
    if (this.currentUser.projectInformation?.registrationFlowLoginUri) {
      const win = window.open(this.currentUser.projectInformation?.registrationFlowLoginUri, '_blank')
      if (win) {
        win.focus()
      }
    }
    this.$router.replace({ name: 'users' })
  }

  constructor () {
    super()
    this.isCancelledAndReadyToEnroll = false
  }

  public getCancellationDate () {
    return formatDate(this.cancellationInfo.cancellationDate)
  }

  public onCancel () {
    this.$router.push({
      name: 'subscriptionCancel',
      query: {
        aborted: 'true'
      }
    })
  }

  public getState () {
    return this.state
  }

  public onSubmitAndEnroll () {
    const api = new SubscriptionApi()
    const cancellationInfo = this.cancellationInfo
    const fields: CancelSubscriptionCommand = {
      subscriptionId: this.currentUser.subscription?.subscriptionId || 0,
      cancellationDate: cancellationInfo.cancellationDate,
      cancelReasonId: cancellationInfo.cancelReasonId,
      uploadGuid: cancellationInfo.cancelUploadGuid,
      explanation: cancellationInfo.cancelExplanation
    }

    api.subscriptionCancelSubscription(fields)
      .then((data) => {
        this.processDate = data.data ? new Date(data.data) : undefined
        this.isCancelledAndReadyToEnroll = true
        this.state = this.states.COMPLETE
      })
      .catch(() => {
        this.processDate = undefined
        this.state = this.states.ERROR
      })
  }

  public onSubmit () {
    const api = new SubscriptionApi()
    const cancellationInfo = this.cancellationInfo
    const fields: CancelSubscriptionCommand = {
      subscriptionId: this.currentUser.subscription?.subscriptionId || 0,
      cancellationDate: cancellationInfo.cancellationDate,
      cancelReasonId: cancellationInfo.cancelReasonId,
      uploadGuid: cancellationInfo.cancelUploadGuid,
      explanation: cancellationInfo.cancelExplanation
    }
    this.state = this.states.LOADING

    api.subscriptionCancelSubscription(fields)
      .then((data) => {
        this.processDate = data.data ? new Date(data.data) : undefined
        this.state = this.states.COMPLETE
      })
      .catch(() => {
        this.processDate = undefined
        this.state = this.states.ERROR
      })
  }
}
